export enum NewPaymentActionTypes {
  SET_AMOUNT = "SET_AMOUNT",
  SET_MESSAGE = "SET_MESSAGE",
  SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS",
  SET_REFRESHED_STATE = "SET_REFRESHED_STATE",
  SET_RECEIVER_IMAGE = "SET_RECEIVER_IMAGE"
}

export const initialState = {
  amount: "",
  message: "",
  name: "",
  receiverName: "",
  email: "",
  receiverImageUrl: "",
};

function newPaymentsReducer(state: any, action: any) {
  switch (action.type) {
    case NewPaymentActionTypes.SET_AMOUNT:
      return {
        ...state,
        ...action.payload,
      };
    case NewPaymentActionTypes.SET_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case NewPaymentActionTypes.SET_RECEIVER_IMAGE:
      return {
        ...state,
        ...action.payload,
      };
    case NewPaymentActionTypes.SET_PERSONAL_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case NewPaymentActionTypes.SET_REFRESHED_STATE:
      return {
        ...state, 
        ...action.payload,
      };
    default:
      return state;
  }
}

export default newPaymentsReducer;
