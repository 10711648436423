export interface Config {
  baseURL: any;
  vgs: { vaultId: string; environment: string; sdkVersion: string };
  stripe: { publicKey: string };
  sentry: { dsn: string };
  homepage: { url: string };
  actionCable: { uri: string };
  isProduction: boolean;
  analytics: string;
}

export const variables: Config = {
  analytics: process.env.REACT_APP_ANALYTICS_TRACKING_ID || "NOT_FOUND_TRACKING_ID",
  vgs: {
    vaultId: process.env.REACT_APP_VGS_VAULT_ID || "",
    environment: process.env.REACT_APP_ENV === "production" ? "live" : "sandbox",
    sdkVersion: process.env.REACT_APP_VGS_SDK_VERSION || "2.9.0",
  },
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  },
  sentry: {
    dsn: process.env.REACT_SENTRY_DSN || "",
  },
  homepage: {
    url: "https://mamopay.com/business",
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  actionCable: {
    uri: process.env.ACTION_CABLE_URI || "ws://localhost:3000/cable",
  },
  isProduction: process.env.REACT_APP_ENV == "production",
};
