import newPaymentsReducer, { initialState } from "reducers/paymentsReducer";
import { Suspense, lazy, useReducer, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "components/Loader/Loader";
import ReactGA from 'react-ga4';
import { variables } from "config/variables";

const Pay = lazy(() => import("./pages/Payment/Pay"));
const PayStep1 = lazy(() => import("./pages/Payment/Pay/Steps/Step1"));
const PayStep2 = lazy(() => import("./pages/Payment/Pay/Steps/Step2"));
const PayStep3 = lazy(() => import("./pages/Payment/Pay/Steps/Step3"));
const PayStep4 = lazy(() => import("./pages/Payment/Pay/Steps/Step4"));
const PaySuccess = lazy(() => import("./pages/Payment/PaySuccess"));
const Inactive = lazy(() => import("./pages/Inactive"));

ReactGA.initialize(variables.analytics);

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

export function App() {
  usePageViews()
  const [state, dispatch] = useReducer(newPaymentsReducer, initialState);

  return (
    <Suspense fallback={Loader({ toFit: false })}>
      <Routes>
        <Route index path="/link-inactive" element={<Inactive />} />
      </Routes>
      <Routes>
        <Route
          path="/pay/:slug"
          element={<Inactive />}

        />
      </Routes>
    </Suspense>
  );
}

export default App;
