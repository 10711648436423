import Logo from "../Logo/Logo";

interface IProps {
  toFit?: boolean;
}

const Loader = ({ toFit = false }: IProps) => {
  const extraFitStyle = { height: "100%"}
  return (
    <section style={toFit ? extraFitStyle: {}} className="loader-wrapper">
      <div className="loader-wrapper__spinner">
        <Logo height="48" width="48" fill="#3333ff" />
      </div>
    </section>
  );
};

export default Loader;
