interface IProps {
  height?: string;
  width?: string;
  fill?: any;
}

const Logo = ({ height, width, fill }: IProps) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 40 40"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m19.9756 2.00003c-3.559.00483-7.0367 1.06461-9.99361 3.04541-2.95687 1.98079-5.26017 4.79369-6.61881 8.08316-1.35863 3.2895-1.71161 6.9079-1.01433 10.398.69729 3.49 2.41355 6.695 4.93186 9.2099s5.72569 4.2269 9.21659 4.9194c3.491.6926 7.109.3347 10.3966-1.0284 3.2877-1.3631 6.0974-3.6702 8.0742-6.6298 1.9768-2.9595 3.0319-6.4387 3.0319-9.9977.0012-2.3662-.4643-4.7094-1.37-6.8954s-2.2337-4.17187-3.9079-5.84389c-1.6743-1.67202-3.662-2.99733-5.8492-3.90004-2.1873-.90271-4.5311-1.36508-6.8973-1.36064zm.0188 32.39887c-2.8479 0-5.6317-.8445-7.9996-2.4267-2.36789-1.5821-4.21343-3.8309-5.30325-6.462-1.08982-2.631-1.37496-5.5262-.81938-8.3193.55559-2.7931 1.92695-5.3587 3.94066-7.37242 2.01377-2.01371 4.57937-3.38507 7.37247-3.94065 2.7931-.55559 5.6882-.27044 8.3193.81937 2.631 1.08982 4.8798 2.93536 6.462 5.3032 1.5822 2.3679 2.4266 5.1518 2.4266 7.9996.0005 1.891-.3716 3.7636-1.095 5.5108s-1.784 3.3347-3.1212 4.6719c-1.3372 1.3371-2.9247 2.3977-4.6718 3.1211-1.7472.7235-3.6198 1.0956-5.5108 1.0951zm11.1263-14.3275c0 2.9508-1.1722 5.7809-3.2588 7.8675-2.0866 2.0865-4.9166 3.2588-7.8675 3.2588s-5.7809-1.1723-7.8675-3.2588c-2.0866-2.0866-3.25884-4.9167-3.25884-7.8675z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
