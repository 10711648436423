import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import "./scss/main.scss";


document.addEventListener("DOMContentLoaded", () => {
  render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    document.body.appendChild(document.createElement("root")),
  );
});
